@keyframes barrelRoll {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  30%,
  55%,
  70%,
  80%,
  90%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-25px);
  }
  45% {
    transform: translateY(-12px);
  }
  63% {
    transform: translateY(-7px);
  }
  75% {
    transform: translateY(-3px);
  }
  85% {
    transform: translateY(-2px);
  }
  95% {
    transform: translateY(-1px);
  }
}

@keyframes fanfare {
  0%,
  100% {
    transform: scale(1);
  }

  30% {
    transform: scale(0.8) rotateZ(5deg);
  }
  40%,
  60%,
  80% {
    transform: scale(1.15) rotateZ(-5deg);
  }
  50%,
  70%,
  90% {
    transform: scale(1.15) rotateZ(5deg);
  }
}

@keyframes flip {
  from {
    transform: perspective(400px) rotateY(-360deg) scale3d(1, 1, 1);
  }
  50% {
    transform: perspective(400px) rotateY(-180deg) scale3d(1.2, 1.2, 1.2);
  }
  to {
    transform: perspective(400px) rotateY(0deg) scale3d(1, 1, 1);
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.2);
  }
  20% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes jello {
  from,
  11.1%,
  to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0px);
  }
  12.5%,
  37.5%,
  62.5%,
  87.5% {
    transform: translateX(4px);
  }
  25%,
  50%,
  75% {
    transform: translateX(-4px);
  }
}

@keyframes spin {
  0% {
    transform: scale(1) rotate(0deg);
  }
  20% {
    transform: scale(2) rotate(0deg);
  }
  40% {
    transform: scale(2) rotate(360deg);
  }
  60% {
    transform: scale(1) rotate(360deg);
  }
  80% {
    transform: scale(1) rotate(0deg);
  }
}

@keyframes spin2 {
  0% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: rotateZ(0deg);
    transform-origin: center center;
  }
  20% {
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: rotateZ(-20deg);
    transform-origin: center center;
  }
  70% {
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    transform: rotateZ(380deg);
    transform-origin: center center;
  }
  100% {
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    transform: rotateZ(360deg);
    transform-origin: center center;
  }
}

@keyframes stretch {
  0%,
  100% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.3, 0.7, 1);
  }
  40% {
    transform: scale3d(0.7, 1.3, 1);
  }
  60% {
    transform: scale3d(1.2, 0.8, 1);
  }
  75% {
    transform: scale3d(0.8, 1.2, 1);
  }
  90% {
    transform: scale3d(1.05, 0.95, 1);
  }
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes wabble {
  0%,
  100% {
    transform: rotateZ(0deg);
  }
  12.5% {
    transform: rotateZ(-20deg);
  }
  25% {
    transform: rotateZ(18deg);
  }
  37.5% {
    transform: rotateZ(-15deg);
  }
  50% {
    transform: rotateZ(13deg);
  }
  62.5% {
    transform: rotateZ(-10deg);
  }
  75% {
    transform: rotateZ(7deg);
  }
  87.5% {
    transform: rotateZ(-3deg);
  }
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
