#background {
  position: fixed;
  inset: min(-100vw / 2, -100vh / 2);

  animation: background 60s linear infinite alternate;
  background-image: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    lime,
    cyan,
    dodgerblue,
    blue,
    indigo,
    darkviolet,
    red
  );
  background-repeat: repeat-x;
  background-size: 600% 100%;
}

.floaty {
  cursor: pointer;
  position: absolute;
  animation: floaty 3s infinite alternate,
    backgroundColor 60s infinite alternate;
    color: rgba(255, 255, 255, 0.2);
}

@keyframes background {
  from {
    transform: rotate(0deg);
    background-position-x: 0;
  }

  to {
    transform: rotate(360deg);
    background-position-x: 100%;
  }
}

@keyframes backgroundColor {
  0% {
    color: rgba(255, 255, 255, 0.2);
  }
  10% {
    color: rgba(255, 255, 255, 0.4);
  }
  20% {
    color: rgba(255, 255, 255, 0.6);
  }
  30% {
    color: rgba(255, 255, 255, 0.4);
  }
  40% {
    color: rgba(255, 255, 255, 0.3);
  }
  50% {
    color: rgba(255, 255, 255, 0.3);
  }
  60% {
    color: rgba(255, 255, 255, 0.2);
  }
  70% {
    color: rgba(255, 255, 255, 0.2);
  }
  80% {
    color: rgba(255, 255, 255, 0.2);
  }
  100% {
    color: rgba(255, 255, 255, 0.2);
  }
}

@keyframes floaty {
  from {
    margin-top: 0;
  }

  to {
    margin-top: 20px;
  }
}
