@import "./background.css";
@import "./effects.css";
@import "./entrances.css";
@import "./../node_modules/bootstrap-icons/font/bootstrap-icons.css";

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  inset: 0;
}

.char {
  cursor: pointer;
  position: fixed;
  font-size: min(80vw, 80vh);
  text-shadow: 0 0 min(3vw, 3vh) white;
  line-height: 1;
  opacity: 0.8;
  z-index: 2;
}

:focus {
  outline: none;
}

[contenteditable] {
  caret-color: transparent;
}